import React from 'react';
import BasicPageStyles from '../styles/BasicPageStyles';

//TODO: style this so you can actually see the message


export default function FourOhFourPage() {
  return (
      <BasicPageStyles>
        <p>Howdy, That page doesn't exist... Yet???</p>
      </BasicPageStyles>

  )
};
